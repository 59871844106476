import axios from 'axios'

const axiosInstance = axios.create({
    // baseURL:"http://82.157.9.26:8890/api/admin",
    timeout:5000,
    // headers:{
    //   // 'Authorization':AUTH_TOKEN,
    //     // JSON格式的请求头
    //     // 'Content-Type':'application/json',
    //     // form请求头
    //     'Content-Type':'application/x-www-form-urlencoded'
    // },
    // transformRequest:[function (data){
    //     if(data instanceof FormData) return data;
    //     if(data instanceof URLSearchParams) return data;
    //     // if(headers['Content-Type'].includes('application/x-www-from-urlencoded')) return Qs.stringify(data)
    //     // if(headers['Content-Type'].includes('json')) return JSON.stringify(data)
    //     return Qs.stringify(data)
    // }]
})

// 添加请求拦截器
axiosInstance.interceptors.request.use(function (config) {
    // 全局请求头，权限，在拦截器中判断，有 才传。 可以存在cookies中
  //   'Authorization':AUTH_TOKEN,
  //   VueCookies.set('Authorization',1111)
  //   在发送请求之前做些什么
    if(localStorage.getItem('token')){
       config.headers.token = localStorage.getItem('token')
    }
    // if(VueCookies.isKey('Authorization')){
    //     
    // }
    return config;
  }, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  });

// 添加响应拦截器
axiosInstance.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。 
  // 对响应数据做点什么
  return response.data
  
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error.message);
});

export default axiosInstance