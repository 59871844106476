// 配置路由
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    publicPath: './',
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
    routes: [
        {
            path: '/',
            name: 'Home',
            component: () => import('../pages/index'),
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../pages/login'),
        },
        {
            path: '/put',
            name: 'put',
            component: () => import('../pages/put'),
        },
        {
            path: '/email',
            name: 'email',
            component: () => import('../pages/email'),
        },
        {
            path: '/media',
            name: 'media',
            component: () => import('../pages/media'),
        },
        {
            path: '/background',
            name: 'background',
            component: () => import('../pages/background'),
            meta: { requiresAuth: true } // 需要登录才能访问
        },
        {
            path: '/pay',
            name: 'pay',
            component: () => import('../pages/pay'),
            meta: { requiresAuth: true } // 需要登录才能访问
        },
    ]

})



// 导航守卫  检测登录
router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // 判断用户是否已经登录
        if (!isAuthenticated()) {
            // 如果没有登录，则重定向到登录页面
            next({
                path: '/login',
                // query: { redirect: to.fullPath }
            })
        } else {
            next()
        }
    } else {
        next()
    }
})

// 判断用户是否已经登录的函数
function isAuthenticated() {
    // 在这里根据你的业务逻辑判断用户是否已经登录
    return localStorage.getItem('token') !== null
}

export default router

  