<template>
  <div
    style="display: flex; width: 1000px; margin: 0 auto; margin-top: 100px"
    class="between"
  >
    <div class="demand">
      <p class="f_weight">联系我们</p>
      <div>
        <img
          src="../assets/imgs/form/email.png"
          alt=""
        />xiong.liu@i-changemaker.com
      </div>
      <div>
        <img
          src="../assets/imgs/form/address.png"
          alt=""
        />http://www.i-changemaker.com
      </div>
      <div>
        <img
          src="../assets/imgs/form/location.png"
          alt=""
        />杭州市西湖区竞舟北路89号瑞泽大厦4号楼1503
      </div>
      <p>北京市朝阳区北苑东路水岸南街1号中合国青大厦4层</p>
    </div>
    <div class="form">
      <p class="f_weight">获取营销解决方案</p>
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item style="width: 400px" prop="company">
          <el-input
            placeholder="请填写公司名称"
            v-model="form.company"
          ></el-input>
        </el-form-item>
        <el-form-item prop="name">
          <el-input placeholder="请填写您的姓名" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item prop="phone">
          <el-input
            placeholder="请填写联系电话"
            v-model="form.phone"
          ></el-input>
        </el-form-item>
        <el-form-item prop="demand">
          <el-input
            placeholder="请填写您的诉求"
            v-model="form.demand"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getScheme('form')">获取方案</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { suggestAdd } from "../http/api";                    
export default {
  name: "formEnd",
  data() {
    return {
      form: {
        name: "",
        company: "",
        phone: "",
        demand: "",
      },
      rules: {
        name: [{ required: true, message: "请填写姓名", trigger: "change" }],
        company: [{ required: true, message: "请填写公司", trigger: "change" }],
        phone: [{ required: true, message: "请填写联系电话", trigger: "change" }],
        demand: [{ required: true, message: "请填写诉求", trigger: "change" }],
      },
     
    };
  },
  methods: {
    getScheme(form){
      var params = {
        username:this.form.name,
        companyName:this.form.company,
        mobile:this.form.phone,
        type:1,
        remark:this.form.demand,
      }
      this.$refs[form].validate((valid) => {
          if (valid) {
            suggestAdd(params).then((res) =>{
              if(res.code == 0){
                this.$message.success({ duration: 2000, message: "感谢您的反馈！我们会尽快联系您。" });
                this.$refs[form].resetFields();
              }else{
                this.$message.error({ duration: 2000, message: res.message });
              }
              
            })
          } else {
            return false;
          }
        });
    }
  },
  mounted() {
    
  },
};
</script>

<style scoped lang='scss'>
.demand > div {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.demand img {
  width: 50px;
}
.demand p:first-child {
  font-size: 26px;
  margin-bottom: 30px;
}
.demand p:last-child {
  margin-left: 50px;
  font-weight: 600;
}
.form > p {
  font-size: 26px;
  margin-bottom: 30px;
}
::v-deep .el-input input {
  border-radius: 20px;
}
::v-deep .el-form-item__content {
  text-align: center;
}
::v-deep .el-button {
  border-radius: 20px;
}
</style>
