import axiosInstance from "./index"



// const url = "http://192.168.7.184:8088"   // 云阳
const url = 'http://jhyx.91s2b2c.com'
// const url = "http://192.168.7.111:8888/api/admin"


// 获取方案提交
export function suggestAdd(data){
    return axiosInstance({
        method:'post',
        url: `${url}`+"/Index/suggest/suggestAdd",
        data
    })
}

// 账号密码登录
export function loginAccount(data){
    return axiosInstance({
        method:'post',
        url: `${url}`+"/Index/Login/loginAccount",
        data
    })
}

// 获取验证码
export function send(data){
    return axiosInstance({
        method:'post',
        url: `${url}`+"/Index/sms/send",
        data
    })
}

// 验证码登录
export function loginMobile(data){
    return axiosInstance({
        method:'post',
        url: `${url}`+"/Index/Login/loginMobile",
        data
    })
}

// 完善信息
export function ImproveInformation(data){
    return axiosInstance({
        method:'post',
        url: `${url}`+"/Index/Login/ImproveInformation",
        data,
    })
}

// 热门产品
export function Product(params){
    return axiosInstance({
        method:'get',
        url: `${url}`+"/Index/Product/index",
        params,
    })
}

// 消息通知
export function Message(params){
    return axiosInstance({
        method:'get',
        url: `${url}`+"/Admin/Message/index",
        params,
    })
}

// 修改密码
export function updatePassword(data){
    return axiosInstance({
        method:'post',
        url: `${url}`+"/Index/Login/updatePassword",
        data,
    })
}


// 扫码登录
export function loginScan(params){
    return axiosInstance({
        method:'get',
        url: `${url}`+"/Index/login/loginScan",
        params,
    })
}

// 退出登录
export function loginout(params){
    return axiosInstance({
        method:'get',
        url: `${url}`+"/Index/Login/loginout",
        params,
    })
}

var urlJava = 'http://82.157.29.189:8881';

// 各余额
export function userAmountDetails(data){
    return axiosInstance({
        method:'post',
        url: `${urlJava}`+"/pay/userAmountDetails",
        data,
    })
}

// 充值
export function pay(data){
    return axiosInstance({
        method:'post',
        url: `${urlJava}`+"/pay/pay",
        data,
    })
}

// 充值是否成功
export function query(params){
    return axiosInstance({
        method:'get',
        url: `${urlJava}`+"/pay/query",
        params,
    })
}

// var commomUrl = "https://testcontent.91s2b2c.com";
var commomUrl = "https://content.91s2b2c.com";
// 分发平台 自动登录 获取token
export function autoRegisterLogin(params){
    return axiosInstance({
        method:'get',
        url: `${commomUrl}`+"/api/auth/autoRegisterLogin",
        params,
    })
}


// mbox 自动登录 获取token
export function mboxAutoRegisterLogin(params){
    return axiosInstance({
        method:'get',
        url: "http://82.157.29.189:9981/user/automaticLogin",
        params,
    })
}


// 获取内容发布平台内容统计数量
export function contetnCount(params){
    return axiosInstance({
        method:'get',
        url: "https://content.91s2b2c.com/web/statistics/count",
        params
    })
}
// 获取投放展示量
export function getShowDataApi(params){
    return axiosInstance({
        method:'get',
        url: "https://aiad.91s2b2c.com/api/hourlyData/getShowData/" + params.id,
    })
}

// 交易明细
export function dealLogApi(data){
    return axiosInstance({
        method:'post',
        url: `${urlJava}`+"/pay/selectOzOrderList?page=" + data.page + '&pageSize=' + data.pageSize,
        data: {userIdentifies: data.userIdentifies}
    })
}
// AI内容梳理统计
export function writeHistoryTotal(params){
    return axiosInstance({
        method:'get',
        url: "http://82.157.29.189:9981/api/writeHistoryTotal",
        params
    })
}
// 邮箱统计
export function emailCountApi(data){
    return axiosInstance({
        method:'get',
        url: "https://testoosp.91s2b2c.com/api/ses/getUserServiceTotal",
        data
    })
}
